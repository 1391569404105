$default-color: #d23c1c;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;



$screen-xs:                  480px !default;
$screen-sm:                  767px !default;
$screen-md:                  1024px !default;
$screen-lg:                  1299px !default;
$screen-xlg:                  1799px !default;

$white:#fff;
$black:#000;
$color1:#EBEBEB;
$color2:#1B1B1B;
$color3:#CCCCCC;
$color4:#333333;
$color5:#FF3535;
$color6:#C0C0C0;
$color7:#F5F5F5;
$color8:#666666;
$color9:#BBBBBB;
$color10:#D1D1D1;
$color11:#8B8B8B;
$color12:#222222;

