.contactForm{

  @media (max-width:$screen-lg){
    margin-top:50px;
  }

  &__row{
    display: flex;
    flex-direction: row;
    margin:0 -7px;

    @media (max-width:$screen-sm){
      flex-direction: column;
      margin: 0;

    }
  }

  &__col{
    padding:0 7px;
    max-width:313px;

    @media (max-width:$screen-sm){
      padding:0;
      max-width:100%;
    }
  }

  &__input{
    width:100%;
    box-sizing: border-box;
    height:40px;
    border:1px solid $color6;
    font-family: 'Nunito', sans-serif;
    font-size: 1.6em;
    padding:5px;
    margin-bottom:16px;
    outline: none;

    &::placeholder{
      color:$color2;

      font-style: italic;
      font-weight: 400;
    }

    &--textarea{
      height:208px;
      min-height: 208px;
      max-height: 208px;
      max-width:313px;
      min-width:313px;

      @media (max-width:$screen-sm){
        max-width:100%;

      }
    }
  }

&__button{
  outline:0;
  border:0;
  cursor: pointer;
  margin-top:15px;
  min-height: 52px;
  position: relative;

  &:focus{
    background: $color5;
  }
}


  &__inputText{
    width: calc(100% - 25px);
    float: right;
    color:$color2;

  }

  &__checkboxLabel{
    font-size:10px;
    color:$color6;
    font-weight:400;
    margin-top:35px;
    width:100%;
    position:relative;
    font-size:1.3em;
  }

  &__more{
    color:$color2;
    font-weight: bold;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    &:hover,&:focus{
      color:$color5;
    }
  }

  &__moreText{
    display:none;
  }


  &__checkbox{
    opacity:0;
  }

  &__checkboxView{
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    background: $white;
    display: block;
    top: -2px;
    border: 1px solid $color8;

    &.active{
      &::before,&::after{
        opacity:1;
      }
    }

    &::after{
      content: "";
      display: block;
      width: 13px;
      height: 2px;
      background: $color5;
      position: absolute;
      top: 8px;
      right: -1px;
      -webkit-transform: rotate(130deg);
      -moz-transform: rotate(130deg);
      -ms-transform: rotate(130deg);
      -o-transform: rotate(130deg);
      transform: rotate(130deg);
      opacity:0;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
      transition: opacity 0.3s;
    }

    &::before{
      content: "";
      display: block;
      width: 8px;
      height: 2px;
      background: $color5;
      position: absolute;
      top: 10px;
      left: 1px;
      -webkit-transform: rotate(-130deg);
      -moz-transform: rotate(-130deg);
      -ms-transform: rotate(-130deg);
      -o-transform: rotate(-130deg);
      transform: rotate(-130deg);
      opacity:0;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
      transition: opacity 0.3s;
    }
  }

  &__loading{
    width: 20px;
    height: 20px;
    border:4px solid $color3;
    border-top:4px solid $color2;
    border-right:4px solid rgba(0,0,0,0);
    border-left:4px solid rgba(0,0,0,0);
    border-radius:100%;
    margin:0px auto;
    -webkit-animation: loading-animation 1s ease infinite;
    -o-animation: loading-animation 1s ease infinite;
    animation: loading-animation 1s ease infinite;
    display:none;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin: auto;
  }


  &__captcha{
    @media (max-width:$screen-sm){
      margin-top:15px;
    }
  }

  &__alert{
    font-size:1.6em;
    color:$color4;
    ul{
      padding:0;
      padding-left:20px;
    }


    &--danger{
      color:$color5;
    }

    &--success{
      font-size:2em;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width:$screen-lg){
        padding-bottom:30px;
      }

      img{
        display:block;
        margin-right:15px;
      }
    }

  }


}

@keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}