.wcag {

  display:flex;

  @media (max-width:$screen-lg){
    justify-content: flex-end;
    margin-right: 76px;
  }


  @media (max-width:$screen-sm){
    position:static;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0;
  }

  &__link {
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    display: block;
    margin: 0 5px;
    position:relative;
    background: $white;

    &:hover,&:focus{
      text-decoration:none;
    }

    &:hover,&.active{
      border-color:#ED174C;
      box-shadow:0 0 0 1px #ED174C inset;

    }


    .wcag__icon {
      font-size: 16px;
      bottom: -2px;


      &--small{
        bottom:-1px;
        font-size:13px;
      }

      &--medium{
        bottom:-3px;
        font-size:16px;
      }

      &--large{
        bottom:-4px;
        font-size:20px;
      }

    }


  }

  &__blackContrast{
    font-size:20px;
    background:#000;

  }

  &__yellowContrast{
    margin-right:25px;
    background: #ff0;
  }

  &__normal{
    font-size:20px;
  }

  &__icon {
    position:absolute;
    right:0;
    left:0;
    margin:auto;
    color: #000;
    font-weight: bold;
    text-align:center;


    &--blackContrast{
      color:#fff;
    }




  }



}