.header {
  display: none;
  background: $color1;
  padding: 10px 15px;
  padding-right: 6px;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  @media (max-width: $screen-lg) {
    display: flex;
  }

  @media (max-width:$screen-sm){
    flex-direction: column;
    padding-left:15px;
    padding-right:15px;
    justify-content: center;
    align-items: center;
  }

  &__logo{
    @media (max-width:$screen-lg){
      margin-top: -24px;
    }
    @media (max-width:$screen-sm){
      margin-top: 0px;
    }
  }


  &__img{
    @media (max-width:$screen-sm){
      max-width: 250px;
      display: block;
      margin:  auto;

    }
  }
}