.menu {
  padding-bottom: 50px;

  @media (max-width:$screen-lg){
    padding-bottom:100px;
  }

  &__ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__li {
    &.active {
      .menu__link {
        background: $color4;
        color: $white;
      }
    }
  }

  &__link {
    display: block;
    font-size: 1.6em;
    color: $color2;
    text-decoration: none;
    padding: 20px 0;
    padding-left: 55px;
    transition: color 0.3s, background 0.3s;
    border-bottom: 1px solid $color9;

    @media (max-width:$screen-lg){
      padding-left:25px;
    }

    &:hover {
      background: $color5;
      color: $white;
    }
  }

  &__dropdown {
    background: $color1;
    display: none;
    .menu__link {
      padding-left: 80px;
      font-size: 1.4em;

      @media (max-width:$screen-lg){
        padding-left:50px;
      }
    }
  }

  &__openList {
    padding-right: 40px;
    position: relative;


    &.open{
      background:$color5;
      color:$white;
      &::after{
        transform:rotate(-135deg);
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
      }

      &:hover{
        color:$white;
        &::after{
          border-right: 2px solid $white;
          border-bottom: 2px solid $white;
        }
      }
    }

    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border: 2px solid rgba(0, 0, 0, 0);
      border-right: 2px solid $color5;
      border-bottom: 2px solid $color5;
      transform: rotate(45deg);
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: border-color 0.3s, transform 0.3s;
    }

    &:hover {
      &::after {
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
      }
    }
  }
}


.menuButton{
  display: none;
  width:62px;
  background: $color4;
  position: fixed;
  top:60px;
  right:0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:54px;
  width:62px;
  z-index: 100;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;

  &--sidebar{
    position: absolute;
    top:0;
  }

  &--default{
    font-size:0;
  }

  @media (max-width:$screen-lg){
    display: flex;
    top:0;
  }

  span{
    display: block;
    width:45px;
    height:4px;
    margin:0 auto;
    background: $white;
    margin-bottom:7px;
    transition:opacity 0.3s, transform 0.3s;

    &:nth-child(3){
      margin-bottom:0;
    }
  }

  &.active{

    background: $color5;
    span{

      &:nth-child(1){
        transform: rotate(45deg);
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        margin:auto;
      }

      &:nth-child(2){
        opacity:0;
      }


      &:nth-child(3){
        transform: rotate(-45deg);
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        margin:auto;
      }
    }
  }
}