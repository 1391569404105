.sidebar{
  position: fixed;
  left:0;
  top:0;
  width:400px;
  height:100%;
  background: $white;
  box-shadow:0 0 15px 0 rgba(0,0,0,0.5);
  overflow: hidden;
  padding-bottom:100px;
  z-index: 2;
  transition: left 0.3s ease-in-out;

  @media (max-width:$screen-xlg){
    width:290px;
  }

  @media (max-width:$screen-lg){
    box-shadow: none;
    left:-290px;
    overflow-y: auto;
    scroll-behavior: smooth;

    &.show{
      left:0;
      box-shadow:0 0 15px 0 rgba(0,0,0,0.5);
    }
  }

  &__logo{
    margin-bottom:20px;
  }

  &__header{
    background: $color1;
    padding:15px;
    padding-bottom:50px;

    @media (max-width:$screen-lg){
      display: none;
    }
  }



  &__text{
    color:$color2;
    font-size:1.8em;
    line-height: 120%;
    &--marginTop{
      margin-top:15px;
    }
    a{
      color:$color2;
      text-decoration: none;
      word-break: break-word;
      &:hover,&:focus{
        color:$color2;
      }
    }
  }

  &__menuText{
    font-weight:bold;
    color:$white;
    font-size:36px;
    display: block;
    padding: 3px;
    padding-left:22px;
    background: $color5;
    display: none;
    position: sticky;
    top:0;
    z-index: 1;

    @media (max-width:$screen-lg){
      display: block;
    }
  }
}