.page{

  &__subText{
    font-size:15px;
    font-weight:bold;
    margin:-12px 0;
    margin-bottom:-17px;
    text-transform: none;
  }

  &__text{
    font-size:16px;
    line-height:200%;
    color:$color4;
    margin:20px 0;

    &--color{
      color:$color10;
    }

    &--large{
      font-size:20px;
      font-weight: bold;
    }

    &--center{
      text-align: center;
    }

  }

  &__description{
    font-size:20px;
    color:$color3;
    text-align:center;
    font-weight:bold;
    line-height:160%;
    max-width:577px;
    margin:26px auto;
    margin-bottom:40px;

    @media (max-width:$screen-md){
      font-size:18px;
    }
  }


  &__button {
    background:$color2;
    color:$white;
    font-size:20px;
    text-transform: uppercase;
    font-weight: bold;
    padding:12px 5px;
    width:330px;
    text-align: center;
    text-decoration: none;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    outline:none;
    border:0;
    margin:0 auto;
    margin-top:50px;
    cursor: pointer;
    display: block;

    &:hover{
      color:$color3;
    }
  }


  &__desc{
    line-height:160%;
    font-size:20px;
    max-width:580px;
    margin:0 auto;
    color:$color10;
    text-align:center;
    font-weight:bold;
    margin-top:15px;
    letter-spacing: 1px;

    @media (max-width:$screen-md){
      font-size:18px;
    }
  }

  &__error{
    display:block;
    margin:0 auto;
    margin-top:50px;
  }

  &__content{
    margin-top:50px;
  }

  &__center{
    text-align: center;
    margin:40px 0;
    margin-top:60px;
  }

  &__return{

    font-weight:bold;
    text-transform: uppercase;
    font-size:15px;
    text-decoration: none;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    padding-left:17px;
    position: relative;

    &:hover{
      opacity:0.8;
    }

    &::before{
      content:'';
      display:block;
      width:8px;
      height:8px;
      position:absolute;
      left:0;
      top:0;
      bottom:0;
      margin:auto;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  &__link {
    font-size: 20px;
    display: block;
    text-decoration: none;
    color: $color2;
    text-transform: uppercase;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    background: $color2;
    color: $white;
    font-weight: bold;
    padding: 20px 15px;
    border-left: 1px solid $color1;


    &:last-child {
      border-right: 1px solid $color1;
    }

    &.active {
      background: $color3;
      color: $color2;

      &:hover {
        color: $color2;
      }
    }

    &:hover {
      color: $color3;
    }

    @media (max-width: $screen-md) {
      text-align: center;
      padding: 10px;
      border: 0;
      border-top: 1px solid $color1;

    }
  }

  &__list {
    @media (max-width: $screen-md) {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 20px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid $color1;
    border-top: 1px solid $color1;
    margin-bottom: 10px;
    @media (max-width: $screen-md) {
      flex-direction: column;
      background: $white;
      margin-bottom: 0;
      display: none;
      border: 0;
    }
  }

  &__show {
    display: block;
    background: $color2;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    text-align: center;
    text-decoration: none;
    padding: 10px 15px;
    display: none;

    &.open {
      span {
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        top: -2px;
      }
    }

    span {
      margin-left: 5px;
      position: relative;
      top: -5px;
      display: inline-block;
      width: 5px;
      height: 5px;
      border: 2px solid rgba(0, 0, 0, 0);
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: transform 0.3s;
      -moz-transition: transform 0.3s;
      -ms-transition: transform 0.3s;
      -o-transition: transform 0.3s;
      transition: transform 0.3s;
    }

    @media (max-width: $screen-md) {
      display: block;
    }
  }
}