.components {
  padding: 25px 0;

  @media (max-width:$screen-lg){
    padding:15px 0;
  }

  @media (max-width:$screen-sm){
    padding:0;
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin: 0 -30px;


    &--alignItemsCenter {
      align-items: center;
      justify-content: center;

      @media (max-width:$screen-sm){
        margin-bottom:25px;
      }
    }

    @media (max-width:$screen-sm){
      display:block;
      margin-left: 0px;
      margin-right:0px;
    }


    &--gallery{
      margin:0 -15px;
      flex-wrap: wrap;

    }

  }

  &__col {
    width: 50%;
    padding: 0 30px;

    @media (max-width:$screen-sm){
      width:100%;
      padding:0;
      margin-bottom:25px;
    }

  }

  &__text {
    font-size: 1.8em;
    color: $color8;
    line-height: 160%;
    text-align: left;

    &--singleText {
      max-width: 593px;
      text-align: left;
    }

  }

  &__subHeader {
    font-size: 2em;
    color: $color4;
    font-weight: bold;
    line-height: 160%;
    margin-top: 15px;

    @media (max-width:$screen-md){
      font-size:1.8em;
    }

    &--center {
      text-align: center;
    }
  }

  &__img {
    max-width: 100%;
    height: auto;
    display:block;
    margin: 0 auto;

    &--gallery{
      margin:0;
      margin-top:30px;
    }


  }

  &__header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 45px;
    text-decoration: underline;
    color: $color2;

    @media (max-width:$screen-md){
      font-size:3.5em;
    }

    &--center {
      text-align: center;
    }
  }

  &__description {
    font-size: 2em;
    color: $color8;
    font-weight: bold;
    line-height: 160%;

  }

  &__iframe {
    border: 0;
    width: 100%;
    height: 20vw;

    @media (max-width:$screen-lg){
      height:25vw;
    }

    &--center {
      width: 50%;
    }

    @media (max-width: $screen-md) {
      width: 100%;
      max-width:480px;
      height: 25vw;
      margin: 0 auto;
      display: block;
    }

    @media (max-width: $screen-sm) {
      height: 45vw;
    }

  }

  &__gallery {
    padding: 0px;
    text-align:center;

    a{
      margin:0 15px;
      display: block;
    }
  }

  &__photoDescription{
    text-align: center;
    margin-top:7px;
    font-size:1.6em;
    font-weight:bold;
  }
}

