.footer {
  background:$color12;
  padding:40px;

  @media (max-width:$screen-lg){
    padding:20px 15px;
  }

  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0 -15px;

    @media (max-width:$screen-lg){
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__text{
    font-size:1.4em;
    color:$white;
    line-height: 160%;

    @media (max-width:$screen-lg){
     text-align: center;
    }

    &--marginTop{
      margin-top:12px;
    }

    a{
      color:$white;
      text-decoration: none;
      font-size:1.1em;
      &:hover,&:focus{
        color:$white;
      }
    }
  }

  &__col{
    padding:0 15px;
    @media (max-width:$screen-lg){
      padding:0;
      margin-bottom:20px;
    }

    &--right{
      text-align: right;
      @media (max-width:$screen-lg){
       text-align: center;
      }
    }
  }
 }