.search{
  margin-top:45px;
  display: flex;
  align-items: center;

  @media (max-width:$screen-lg){
    margin-top:5px;
  }

  @media (max-width:$screen-sm){
    margin-top:15px;
  }

  &__input{
    height: 40px;
    border:2px solid $color3;
    font-size:1.5em;
    color:$color2;
    outline: 0;
    padding:0 10px;
    width:300px;
    box-sizing: border-box;

    @media (max-width:$screen-xlg){
      width:210px;
    }

    @media (max-width:$screen-lg){
      width: 249px;
    }

    @media (max-width:$screen-sm){
      width: 275px;
    }

    &::placeholder{
      font-style: italic;
    }
  }

  &__button{
    border:0;
    outline: none;
    width:42px;
    height:38px;
    background: url('/media/default/search-button.png')no-repeat;
    font-size: 0;
    margin-left:13px;
    cursor: pointer;
  }
}