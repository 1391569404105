.gallery{
  margin-bottom:30px;

  &__category{
    display:block;
    position: relative;
    text-decoration: none;
    overflow: hidden;

    &:hover{
      .gallery__header{
       bottom:0;
        opacity:1;
      }
    }
  }

  &__header{
    padding:20px;
    background: $color5;
    color:$white;
    font-size:2em;
    position: absolute;
    left:0;
    bottom:-6em;
    right:0;
    transition: bottom 0.5s, opacity 0.5s;
    opacity:0;
  }
}