.section {
  padding: 40px 40px;
  background: $color7;

  @media (max-width: $screen-lg) {
    padding: 20px 15px;
  }

  &--dark {
    background: $color10;
  }

  &--white{
    background: $white;
  }

  &--padding{
   padding-top:100px;
    padding-bottom:100px;

    @media (max-width: $screen-lg) {
      padding-top:20px;
      padding-bottom:20px;
    }
  }

  &--contact {
    background: url('/media/default/kontakt.jpg') no-repeat;
    background-size: cover;
  }

  &__header {
    font-size: 4.2em;
    font-weight: 400;
    color: $color5;
    line-height: 120%;
    margin-bottom: 50px;

    &--dark{
      color:$color4;
    }

    &--center{
      text-align:center;
    }

    &--noMargin{
      margin-bottom:0;
    }


    @media (max-width: $screen-lg) {

    margin-bottom: 30px;
  }

    @media (max-width:$screen-sm){
      font-size:36px;
    }

    span {
      display: block;
      height: 1px;
      background: $color5;
      margin-top: 5px;
      &:nth-child(1) {
        max-width: 360px;
        margin-top: 0;

        @media (max-width:$screen-sm){
          max-width:320px;
        }
      }
      &:nth-child(2) {
        max-width: 277px;

        @media (max-width:$screen-sm){
          max-width:237px;
        }
      }
      &:nth-child(3) {
        max-width: 171px;
        @media (max-width:$screen-sm){
          max-width:131px;
        }
      }
    }

    &--map {
      background: $color11;
      color: $white;
      font-size: 4.2em;
      font-weight: 400;
      padding: 18px 40px;
      margin: 0;

      @media (max-width:$screen-lg){
        padding:15px;
      }
      @media (max-width:$screen-sm){
        font-size:36px;
      }

      span {
        background: $white;
      }
    }

  }

  &__row {
    display: flex;
    flex-direction: row;
    margin: 0 -2%;
    flex-wrap: wrap;

    @media (max-width: $screen-lg) {
      margin: 0;
    }
  }

  &__col {
    width: 29%;
    padding: 0 2%;
    margin-bottom: 60px;

    @media (max-width: $screen-lg) {
      width: 100%;
      padding: 0;
      margin-bottom:40px;
    }
  }

  &__half {
    width: 46%;
    padding: 0 2%;

    @media (max-width: $screen-xlg) {
      &--left {
        width: 40%;
      }

      &--right {
        width: 52%;
      }

    }

    @media (max-width:$screen-lg){
      width:100%;
      padding:0;
    }
  }

  &__block {
    padding-bottom: 20px;
    border-bottom: 1px solid $color2;
    margin-bottom: 7px;
  }

  &__text {
    font-size: 1.8em;
    color: $color8;

    p{
      line-height: 160%;
    }

    @media (max-width:$screen-sm){
      font-size:1.6em;
    }

    &--small {
      font-size: 1.4em;
      margin-bottom: 8px;
      color: $color2;
    }

    &--large {
      font-size: 2em;
      font-weight: bold;
      font-weight: bold;

      @media (max-width:$screen-sm){
        font-size:1.8em;
      }

    }

    &--center{
      text-align: center;
    }

    &--dark {
      color: $color2;
    }

    &--noMargin {
      p {
        margin: 0;
      }
    }
  }

  &__title {

    font-weight: bold;
    color: $color2;
    margin-bottom: 5px;
    line-height: 120%;
    text-decoration: none;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    &:hover {
      color: $color5;
    }
    h3 {
      font-size: 2.3em;
      line-height: 120%;

      @media (max-width:$screen-sm){
        font-size:1.8em;
      }
    }
  }

  &__link {
    color: $color5;
    font-size: 1.8em;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;

    @media (max-width:$screen-sm){
      font-size:1.3em;
    }

    &:hover {
      color: $color2;
    }
  }

  &__button {
    font-size: 1.4em;
    color: $white;
    font-weight: bold;
    display: block;
    background: $color5;
    padding: 18px 10px;
    text-decoration: none;
    width: 13.5em;
    text-transform: uppercase;
    text-align: center;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;

    @media (max-width:$screen-sm){
      width:11.5em;
      font-size:1.3em;
    }

    &:hover {
      background: $color2;
    }
  }

  &__noPadding {
    margin: 0 -40px;
  }

  &__gallery{
    display: flex;
    flex-direction: row;
    margin:0 -15px;
    margin-top:30px;
    flex-wrap: wrap;
    a{
      display: block;
      margin:0 15px;
      margin-bottom:30px;
      text-decoration: none;
      max-width:332px;
      img{
        display: block;

      }
    }

    &Title{
      font-size:1.8em;
      color:$color2;
      padding:10px 0;
      -webkit-transition: color 0.3s;
      -moz-transition: color 0.3s;
      -ms-transition: color 0.3s;
      -o-transition: color 0.3s;
      transition: color 0.3s;
      font-weight: bold;
      &:hover{
        color:$color5;
      }
    }
  }

  &__content{
      max-width:710px;
    margin: 0 auto;
  }


  &__error{
    display: block;
    margin: 20px auto;
  }



}