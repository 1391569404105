html, body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 400;

  @media (max-width: $screen-md - 1px) {
    overflow-x: hidden;
  }

  font-size: 0.625em;
}

body {
  position: relative;
  transition: left 0.3s ease-in-out;
  left: 0;

  &.move {
    left: 290px;
    overflow-y: hidden;
  }
}

.html--font-small {
  font-size: 1em;
}

.html--font-medium {
  font-size: 1.5em;
  h1, h2, h3, h4, h5, h6, .banner__text {
    font-weight: bold;
  }
}

.html--font-large {
  font-size: 2em;
  h1, h2, h3, h4, h5, h6, .banner__text {
    font-weight: bold;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin:0;
  line-height: 120%;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2.35em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.8em;
}

h5 {
  font-size: 1.4em;
}

h6 {
  font-size: 1em;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.overflow {
  overflow: hidden;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
  height: auto;
}

.no-margin {
  margin-top: 0;
}

.pagination-block {
  text-align: center;
  margin-top: 20px;
}

.pagination {
  &__li {

    &.active {
      .pagination__link {
        background: #333;
        border-color: #333;
        color: $white !important;

        &:hover {
          background: #333;
          border-color: #333;
        }
      }
    }
  }

  &__link {
    color: #333 !important;
  }

  li {
    &.disabled {
      display: none;
    }
  }

  li > a {
    color: #333 !important;
    margin: 0 4px;
  }

  li > span {
    margin: 0 4px;
  }
}

.scrollTop {
  width: 60px;
  height: 60px;
  background: $white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.6);
  position: fixed;
  right: 10px;
  -webkit-transition: opacity 0.3s, bottom 07s;
  -moz-transition: opacity 0.3s, bottom 0.7s;
  -ms-transition: opacity 0.3s, bottom 0.7s;
  -o-transition: opacity 0.3s, bottom 0.7s;
  transition: opacity 0.3s, bottom 0.7s;
  bottom: -100px;
  transform:scale(0.8);
  z-index: 1;
  font-size: 0;

  @media (max-width: $screen-lg) {
    right:0;
    transform:scale(1);
  }

  &.show {
    bottom: 10px;

    @media (max-width: $screen-lg) {
      bottom:70px;
    }
  }

  &:hover {
    opacity: 1;

    &::before{
      border-top: 5px solid $color5;
    }

    &::after{
      border-top: 5px solid $color5;
    }
  }

  &::before {
    content: '';
    display: block;
    border-top: 5px solid $color2;
    width: 25px;
    height: 11px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 3px;
    right: 0;
    left: -6px;
    bottom: 0;
    position: absolute;
    margin: auto;
    transition:border-color 0.3s;
  }
  &::after {
    content: '';
    display: block;
    border-top: 5px solid $color2;
    width: 25px;
    height: 11px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 3px;
    right: -7px;
    left: 0;
    bottom: 0;
    transition:border-color 0.3s;
    position: absolute;
    margin: auto;
  }
}

.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: $color2;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &__content {
    animation: rotate 1s cubic-bezier(0.1, 0.8, 0.8, 0.1) infinite;
  }

  &__dot {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid $white;
    display: inline-block;
    margin: 5px;
    background: $white;
    -webkit-transition: transform 1s;
    -moz-transition: transform 1s;
    -ms-transition: transform 1s;
    -o-transition: transform 1s;
    transition: transform 1s;
    animation: show-hide 1s ease infinite;

    &:nth-child(2) {
      animation-delay: 300ms;
    }

    &:nth-child(3) {
      animation-delay: 600ms;
    }
  }
}

@keyframes show-hide {
  0% {
    transform: scale(0);

  }
  50% {
    transform: scale(1);

  }
  100% {
    transform: scale(0);

  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#preloader.preloader-hiding {
  opacity: 0;
}

#preloader.preloader-hidden {
  display: none;
}

.alert {
  ul {
    padding-left: 15px;
  }
}

.main {

  &__col {

    padding-left: 400px;
    overflow-x: hidden;
    position: relative;

    @media (max-width: $screen-xlg) {
      padding-left: 290px;
    }

    @media (max-width: $screen-lg) {
      padding-left: 0;
    }

  }
}

.blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  z-index: 99;
}

.login {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  width: 27em;
  background: $color5;
  -webkit-transition: background 0.3s, right 0.3s;
  -moz-transition: background 0.3s, right 0.3s;
  -ms-transition: background 0.3s, right 0.3s;
  -o-transition: background 0.3s, right 0.3s;
  transition: background 0.3s, right 0.3s;

  @media (min-width: $screen-lg + 1) {
    &:hover {
      background: $color2;
    }
  }

  @media (max-width: $screen-lg) {
    top: auto;
    bottom: 0;
    width: auto;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.6);
    padding: 0;
    width: 350px;
    right: -290px;
    &.open {
      right: 0;
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 10px 20px;
    @media (max-width: $screen-lg) {

      padding-right: 50px;
    }
  }

  &__text {
    color: $white;
    font-size: 1.6em;
    font-weight: bold;
    margin-left: 15px;

    @media (max-width: $screen-lg) {
      margin-left: 23px;
    }

  }

  &__close {
    display: none;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;

    @media (max-width: $screen-lg) {
      display: block;
    }

    span {

      display: block;
      background: $white;
      width: 30px;
      height: 3px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }

  }

}

.pagination-block {

  margin-top: 0px;

  @media(max-width: $screen-lg) {
    margin: 20px 0;
  }
}

.pagination {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;

  &__li {

    &.active {
      .pagination__link {
        background: $color5;
        border-color: $color5 !important;
        color: $white !important;
        font-weight: bold;

        &:hover {
          background: $color5;
        }
      }
    }
  }

  &__link {
    padding: 5px 10px;
    border: 1px solid $color4;
    color: $white;
    margin: 0 4px;
    font-size: 1.4em;
    text-decoration: none;
    -webkit-transition: background-color 0.3s, color 0.3s;
    -moz-transition: background-color 0.3s, color 0.3s;
    -ms-transition: background-color 0.3s, color 0.3s;
    -o-transition: background-color 0.3s, color 0.3s;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background: $color4;
      color: $white !important;
    }

  }

  li {
    &.disabled {
      display: none;
    }
  }

}

