.modal{
  position: fixed;
  background: rgba(0,0,0,0.9);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 101;
padding: 0 15px;
  display: none;
  &__container{
    height: 100%;
    position: relative;
  }

  &__content{
    padding:25px;
    background: $white;
    max-width:600px;
    height:20em;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    box-shadow:0 0 15px 0 rgba(0,0,0,0.5);

    @media (max-width:$screen-sm){
      height:25em;
      padding:15px;
    }
  }

  &__header{
    font-size:2.2em;
    color:$color5;
    font-weight:bold;
    border-bottom:2px solid $color5;
    margin-bottom:20px;
  }

  &__text{
    font-size:1.8em;
    font-weight: bold;
    color:$color2;
    margin:10px 0;
  }

  &__form{
    text-align: center;
    margin-top:20px;
  }

  &__label{
    font-size:1.6em;
  }

  &__input{
    box-sizing: border-box;
    height:40px;
    border:1px solid $color6;
    font-family: 'Nunito', sans-serif;
    font-size: 1.6em;
    padding:5px;
    margin-bottom:16px;
    outline: none;
    width:100%;

    &::placeholder{
      color:$color2;

      font-style: italic;
      font-weight: 400;
    }
  }

  &__button{
    border:0;
    cursor:pointer;
    width:11em;
    outline:none;

  }

  &__close{
    width:25px;
    height:25px;
    position:absolute;
    top:5px;
    right:5px;

    &:hover{
      span{
        background: $color5;
      }
    }

    span{
      transition: background 0.3s;
      width:20px;
      height:3px;
      background:$color11;
      display: block;
      position: absolute;
      right:0;
      left:0;
      top:0;
      bottom:0;
      margin:auto;

      &:nth-child(1){
        transform:rotate(45deg);
      }

      &:nth-child(2){
        transform:rotate(-45deg);
      }
    }
  }
}