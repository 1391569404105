.searchAlert {
  display: flex;
  display: -webkit-flex;
  display: -o-flex;
  display: -moz-flex;
  display: -ms-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;


  &__img {
    display: block;
  }

  &__alert {
    font-weight: bold;
    font-size: 1.6em;
    margin: 20px 0;
    text-align: center;
    line-height: 120%;

    &--error {
      color: $color5;
    }

    &--success {
      color: $color2;
    }

    @media (max-width: $screen-sm) {
      font-size: 2em;
    }
  }

  &__text {
    color: $color4;
    font-size: 1.6em;
    text-align: center;
    line-height: 120%;
  }

  &__button {
    background:$color2;
    color:$white;
    font-size:2em;
    text-transform: uppercase;
    font-weight: bold;
    padding:12px 5px;
    width:190px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    outline:none;
    border:0;
    margin:0 auto;
    margin-top:50px;
    display: block;
    text-decoration: none;
    text-align: center;

    &:hover{
      color:$color3;
    }
  }

  &__header {
    margin:0;
    font-size:2em;

    @media (max-width:$screen-sm){
      font-size:1.6em;
    }

    margin-bottom:10px;

    a {

      position: relative;
      padding-left: 22px;
      text-decoration: none;
      -webkit-transition: color 0.3s;
      -moz-transition: color 0.3s;
      -ms-transition: color 0.3s;
      -o-transition: color 0.3s;
      transition: color 0.3s;

      &::before {
        content: '';
        display: block;
        width: 15px;
        height: 2px;
        background: $color2;
        position: absolute;
        left: 0;
        top:0.65em;
        margin: auto;
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        -ms-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        transition: background-color 0.3s;
      }


      color: $color4;



      &:hover, &:focus {
        text-decoration: none;
        color:$color5;

        &::before{
          background: $color5;
        }

      }

    }
  }

  &__home {
    margin-top:70px;
    a {
      text-transform: uppercase;
      position: relative;
      font-size: 1.6em;
      text-decoration: underline;
      background: $color2;

      position:relative;
      font-weight: 600;

      &::after{
        content:'';
        display:block;
        width:100%;
        height:1px;
        background: $color2;

        position:absolute;
        bottom:0;
        left:0;
        right:0;
      }
    }
  }

  &__content{
    margin-top:20px;
  }
}