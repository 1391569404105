.banner{
  width:100%;
  position: relative;

  &__arrow{
    position: absolute;
    width:58px;
    height:58px;
    background: rgba(0,0,0,0.6);
    outline: none;
    top:46%;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;

    @media (max-width:$screen-lg){
      top: 36%;
    }

    @media (max-width:$screen-sm){
      display: none;
    }

    &:hover{
      background: #000;
    }
    &::before{
      content:'';
      display:block;
      position: absolute;
      border:4px solid rgba(0,0,0,0);
      border-top:4px solid $white;
      border-right:4px solid $white;
      width:25px;
      height:25px;
      top:0;
      bottom:0;
      margin:auto;
      border-radius: 2px;
    }


    &--left{
      left:0;

      &::before{
        transform: rotate(-135deg);
        left:20px;
      }

    }
    &--right{
      right:0;

      &::before{
        transform: rotate(45deg);
        right:20px;
      }

    }
  }

  &__item{
    position: relative;
  }

  &__img{
    display: block;
  }

  &__text{
    color:$color2;
    font-size:42px;
    background:rgba(255,255,255,0.7);
    position: absolute;
    bottom:50px;
    left:0;
    padding: 27px;

    @media (max-width:$screen-lg){
      position: static;
      padding: 15px;
      font-size:32px;
      background: $color1;
    }


    @media (max-width:$screen-sm){
      font-size:20px;
    }
  }

  .swiper-pagination-bullet{
    width:22px;
    height:22px;
    border-radius: 100%;
    background: $white;
    border:3px solid $color5;
    opacity: 1;
    outline: none;
    transition:border-color 0.3s, background 0.3s;
    margin:0 6px !important;

    &-active{
      background: $color5 !important;
      border-color:$white;
    }
  }

  &__pagination{
    @media (max-width:$screen-lg){
      display: none;
    }
  }

}
