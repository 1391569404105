.map{


  &__section{
    height:600px;
    width:100%;
  }

  &__header{
    font-size:1.6em;
    color:$black;
  }

  &__text{
    font-size:1.4em;
    margin:5px 0;
  }

  &__link{
    color:$color5;
    font-weight:bold;
    font-size:1em;
    text-decoration: none;
    text-transform: uppercase;
  }
}