.alerts{
  background: $color5;
  padding:27px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width:$screen-lg){
    padding:20px 15px;
  }

  @media (max-width:$screen-sm){
    flex-direction: column;
    align-items: flex-start;
  }

  &__header{
    font-size: 46px;
    text-transform: uppercase;
    color:$white;
    line-height: 120%;
    font-weight: 400;
    padding-right:30px;

    @media (max-width:$screen-lg){
      font-size:36px;
    }

    @media (max-width:$screen-sm){
      padding:0;
    }



  }

  &__item{
    border-left:1px solid $white;
    padding:8px 0;
    padding-left:30px;
    margin-bottom:30px;

    @media (max-width:$screen-sm){
      border-top:1px solid $white;
      border-left:0;
      padding:5px 0;

    }

    &:last-child{
      margin-bottom:0;
    }
  }

  &__text{
    font-size:2em;
    line-height: 145%;
    color:$white;
    font-weight: 400;

    @media (max-width:$screen-lg){
      font-size:1.6em;
    }
  }
}