.top{
  background: $color1;
  padding:15px 27px;

  @media (max-width:$screen-lg){
    padding: 10px 15px;
  }

  //@media (max-width:$screen-lg){
  //  background: $color1;
  //}
}